
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import _ from "lodash";
import { ApiKyc } from "@/core/api";
import { useRouter } from "vue-router";

import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import { KycType } from "@/core/directive/type/kyc";
import Swal from "sweetalert2";

import MbInputTelephone from "@/components/form/MbInputTelephone.vue";
import MbInputCountry from "@/components/form/MbInputCountry.vue";

export default defineComponent({
  name: "add-kyc-verification-modal",
  components: {
    MbInputTelephone,
    MbInputCountry,
  },
  emits: ["update-list", "reset-form", "modal-hide"],
  setup(props, { emit }) {
    const juridicalFormRef = ref<null | HTMLFormElement>(null);
    const naturalFormRef = ref<null | HTMLFormElement>(null);
    const AddKycVerificationModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();
    const { t } = useI18n();
    const router = useRouter();
    const entityType = getKycCommonTypeParams.value.entityType;
    const navigateType = ref<"add" | "edit" | "">("");
    const navigateId = ref<string>("");

    const submitButton = ref<HTMLElement | null>(null);

    const juridicalFormData = ref({
      company_name: "",
      country_code: "",
      company_registration_number: "",
    });

    const naturalFormData = ref({
      firstname: "",
      lastname: "",
      passport: "",
      country_code: "",
      area_code: "",
      telephone: "",
    });

    const juridicalRules = ref({
      company_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      country_code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      company_registration_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const naturalRules = ref({
      firstname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      area_code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const submit = (type: string) => {
      if (type === "juridical" && !juridicalFormRef.value) {
        return;
      }
      if (type === "natural" && !naturalFormRef.value) {
        return;
      }
      if (type === "juridical" && juridicalFormRef.value) {
        juridicalFormRef.value.validate(async (valid) => {
          if (valid) {
            if (loading.value) return;
            loading.value = true;
            if (submitButton.value) {
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            const { data } = await ApiKyc.addJuridicalKycVerification({
              ...juridicalFormData.value,
            });
            loading.value = false;
            if (data.code === 0) {
              if (data.data.id) {
                navigateType.value = "add";
                navigateId.value = data.data.id;
                showFormSubmitSuccessMsg(() => {
                  modalHide();
                });
              }
            } else {
              showServerErrorMsg(data);
            }
          } else {
            showValidateErrorMsg();
            return false;
          }
        });
      }
      if (type === "natural" && naturalFormRef.value) {
        naturalFormRef.value.validate(async (valid) => {
          if (valid) {
            if (loading.value) return;
            loading.value = true;
            if (submitButton.value) {
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            const { data } = await ApiKyc.addNaturalKycVerification({
              type: entityType,
              ...naturalFormData.value,
            });
            loading.value = false;
            if (data.code === 0) {
              if (data.data.id) {
                navigateType.value = "add";
                navigateId.value = data.data.id;
                showFormSubmitSuccessMsg(() => {
                  modalHide();
                });
              }
            } else {
              showServerErrorMsg(data);
            }
          } else {
            showValidateErrorMsg();
            return false;
          }
        });
      }
    };

    const submitJuridicalForm = () => {
      submit("juridical");
    };

    const submitNaturalForm = () => {
      submit("natural");
    };

    const updateList = () => {
      emit("update-list");
      modalHide();
    };

    const handleModalHidden = () => {
      setTimeout(() => {
        if (navigateType.value === "add") {
          if (entityType === KycType.Juridical) {
            router.push({
              name: "kyc-verification-legal-entity-add",
              params: {
                id: navigateId.value,
              },
            });
          }
          if (entityType === KycType.Natural) {
            router.push({
              name: "kyc-verification-natural-person-add",
              params: {
                id: navigateId.value,
              },
            });
          }
          navigateType.value = ""; // 重置路径
        }
      }, 400);
    };

    const modalShow = () => {
      showModal(AddKycVerificationModalRef.value);
    };

    const modalHide = () => {
      hideModal(AddKycVerificationModalRef.value);
    };

    const resetForm = () => {
      juridicalFormRef.value?.resetFields();
      naturalFormRef.value?.resetFields();
      emit("reset-form");
    };

    onMounted(() => {
      modalShowListener(AddKycVerificationModalRef.value, () => {
        // setTimeout(() => {
        //   getShowInfo();
        // }, 0);
      });
      modalHideListener(AddKycVerificationModalRef.value, handleModalHidden);
    });

    return {
      props,
      router,
      loading,
      juridicalFormData,
      naturalFormData,
      juridicalRules,
      naturalRules,
      juridicalFormRef,
      naturalFormRef,
      AddKycVerificationModalRef,
      modalShow,
      modalHide,
      submitJuridicalForm,
      submitNaturalForm,
      updateList,
      entityType,
      KycType,
    };
  },
});
