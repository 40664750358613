
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { useIntersectionObserver, useVModel } from "@vueuse/core";
import mixin from "@/mixins";

export default defineComponent({
  name: "MbInputTelephone",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const MbInputTelephoneRef = ref();
    const model = useVModel(props, "modelValue", emit);
    const { showServerErrorMsg } = mixin();
    const areaCodesLoading = ref(false);
    const areaCodesOptions = ref<TaggingItem[]>([]);

    const getAreaCodesData = async () => {
      areaCodesLoading.value = true;
      const { data } = await ApiBase.getAreaCodesData({
        max_item: "all",
      });
      areaCodesLoading.value = false;
      if (data.code === 0) {
        areaCodesOptions.value.splice(
          0,
          areaCodesOptions.value.length,
          ...data.data
        );
      } else {
        showServerErrorMsg(data);
      }
    };

    onMounted(() => {
      useIntersectionObserver(
        MbInputTelephoneRef,
        ([{ isIntersecting }], observerElement) => {
          if (isIntersecting) {
            getAreaCodesData();
          }
        }
      );
    });

    return {
      MbInputTelephoneRef,
      model,
      areaCodesLoading,
      areaCodesOptions,
    };
  },
});
