import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    ref: "MbInputTelephoneRef",
    modelValue: _ctx.model.telephone,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.telephone) = $event)),
    placeholder: _ctx.$t('common.plsEnter'),
    class: "input-with-select",
    clearable: "",
    type: "text"
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        class: "hide-error-msg",
        prop: "area_code"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.model.area_code,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.area_code) = $event)),
            clearable: false,
            loading: _ctx.areaCodesLoading,
            placeholder: _ctx.$t('common.plsSelect'),
            autocomplete: "off",
            style: {"width":"130px"},
            filterable: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areaCodesOptions, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: '+' + item.label,
                  value: item.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "loading", "placeholder"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "placeholder"]))
}