import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    ref: "MbInputCountryRef",
    class: "w-100",
    modelValue: _ctx.model.country_code,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.country_code) = $event)),
    loading: _ctx.countryLoading,
    placeholder: _ctx.$t('common.plsSelect'),
    clearable: "",
    onChange: _ctx.countryChange,
    filterable: true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesOptions, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "loading", "placeholder", "onChange"]))
}