import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e616a54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-custom-main" }
const _hoisted_2 = { class: "app-content flex-column-fluid" }
const _hoisted_3 = { class: "app-container" }
const _hoisted_4 = { class: "d-flex flex-column gap-10" }
const _hoisted_5 = { class: "card card-flush" }
const _hoisted_6 = { class: "card-header align-items-center py-5 gap-2 gap-md-5" }
const _hoisted_7 = { class: "card-title flex-row-fluid gap-5" }
const _hoisted_8 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  class: "card-toolbar flex-row-fluid justify-content-end gap-5",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_12 = { class: "business-date-select-box day rounded" }
const _hoisted_13 = {
  class: "btn btn-primary",
  "data-bs-target": "#modal_add_kyc_verification",
  "data-bs-toggle": "modal"
}
const _hoisted_14 = { class: "card-body pt-0" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _component_AddKycModal = _resolveComponent("AddKycModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
                      onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchItems())),
                      class: "form-control form-control-solid w-250px ps-14",
                      placeholder: $setup.t('common.search')
                    }, null, 40, _hoisted_10), [
                      [_vModelText, $setup.search]
                    ]),
                    _withDirectives(_createElementVNode("span", {
                      class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle cursor-pointer",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.removeSearch && $setup.removeSearch(...args)))
                    }, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                    ], 512), [
                      [_vShow, $setup.search != '']
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_el_date_picker, {
                      "popper-class": "date-popover",
                      class: _normalizeClass(["w-100", {
                      'has-value':
                        $setup.options.pick_date_range &&
                        $setup.options.pick_date_range.length,
                    }]),
                      style: _normalizeStyle(`--placeholder:'${_ctx.$t('common.PickDateRange')}'`),
                      modelValue: $setup.options.pick_date_range,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.options.pick_date_range) = $event)),
                      type: "daterange",
                      format: $setup.getDateType.format,
                      "value-format": $setup.getDateType.valueFormat,
                      onChange: $setup.handleFilter,
                      disabled: $setup.tableLoading,
                      "disabled-date": $setup.disabledDate,
                      "range-separator": _ctx.$t('common.dateTo'),
                      "append-to-body": false
                    }, null, 8, ["class", "style", "modelValue", "format", "value-format", "onChange", "disabled", "disabled-date", "range-separator"])
                  ]),
                  _createElementVNode("button", _hoisted_13, _toDisplayString($setup.t("kyc.list.addKyc")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_MBDatatablePlus, {
                  loading: $setup.tableLoading,
                  "table-data": $setup.tableData,
                  "table-header": $setup.tableHeader,
                  order: $setup.sortOrder,
                  "diy-order": true,
                  "sort-label": $setup.sortLabel,
                  "current-page": $setup.currentPage,
                  total: $setup.total,
                  "rows-per-page": $setup.pageSize,
                  showExpandBtn: false,
                  onCurrentChange: $setup.onCurrentPageChange,
                  onItemsPerPageChange: $setup.onRowsPerPageChange,
                  onDiySortClick: $setup.onColumnSort
                }, {
                  "cell-id": _withCtx(({ row: item }) => [
                    _createTextVNode(_toDisplayString(item.id ? item.id : "--"), 1)
                  ]),
                  "cell-company_name": _withCtx(({ row: item }) => [
                    _createTextVNode(_toDisplayString(item.company_name ? item.company_name : "--"), 1)
                  ]),
                  "cell-lastname": _withCtx(({ row: item }) => [
                    _createTextVNode(_toDisplayString(item.lastname ? item.lastname : "--") + " " + _toDisplayString(item.firstname ? item.firstname : "--"), 1)
                  ]),
                  "cell-last_saved_at": _withCtx(({ row: item }) => [
                    (item.last_saved_at)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString($setup.formatUtcDate(item.last_saved_at, "YYYY-MM-DD")), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_16, "--"))
                  ]),
                  "cell-actions": _withCtx(({ row: item }) => [
                    _createVNode(_component_router_link, {
                      to: $setup.getJumpRoutes(item),
                      class: "btn btn-light btn-active-light-primary btn-sm"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 1
                }, 8, ["loading", "table-data", "table-header", "order", "sort-label", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onDiySortClick"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_AddKycModal, { onUpdateList: $setup.updateList }, null, 8, ["onUpdateList"])
  ], 64))
}