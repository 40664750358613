
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { useIntersectionObserver, useVModel } from "@vueuse/core";

export default defineComponent({
  name: "MbInputCountry",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const MbInputCountryRef = ref();
    const model = useVModel(props, "modelValue", emit);
    const countryLoading = ref(false);
    const countriesOptions = ref<TaggingItem[]>([]);

    const getCountryData = async () => {
      countryLoading.value = true;
      const { data } = await ApiBase.getCountryData();
      countryLoading.value = false;
      if (data.code == 0) {
        countriesOptions.value.splice(
          0,
          countriesOptions.value.length,
          ...data.data
        );
      }
    };

    const countryChange = () => {
      emit("change", model.value.country_code);
    };

    onMounted(() => {
      useIntersectionObserver(
        MbInputCountryRef,
        ([{ isIntersecting }], observerElement) => {
          if (isIntersecting) {
            getCountryData();
          }
        }
      );
    });

    return {
      MbInputCountryRef,
      model,
      countryLoading,
      countriesOptions,
      countryChange,
    };
  },
});
